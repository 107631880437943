.toast-dark {
  background-color: #c0c9d7;
  color: #4a4a4a;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.offcanvas {
  background-color: #1651b1; /* Dark background for the drawer */
  opacity: 0.85;
  color: #f8f8ff; /* Light text color */
}

/* Styling for the toast header */
.toast-dark .toast-header {
  background-color: #1651b1;
  color: #ffffff;
  border-bottom: 1px solid #c0c9d7;
  padding: 10px;
}

/* Styling for the toast body */
.toast-dark .toast-body {
  background: linear-gradient(to right, #c0c9d7, #e0e4eb, #c0c9d7);

  color: #4a4a4a;
  padding: 15px;
}

.toast-box {
  position: relative;
  width: 100%;
  max-width: 80%;
  margin: 20px auto;
  padding: 20px;
}

.divider-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.divider {
  border: none;
  height: 2px; /* Adjust the height of the divider */
  width: 80%;
  background: linear-gradient(to right, #415370, #e0e4eb, #415370);
  position: relative;
}

.divider::after {
  content: "";
  display: block;
  height: 4px; /* Height of the highlight */
  width: 100%;
  background: linear-gradient(to right, #c0c9d7, #e0e4eb, #c0c9d7);
  position: absolute;
  top: -1px;
  left: 0;
  z-index: 1;
  pointer-events: none;
}

.markdown-container {
  background-color: #1e1e1e; /* Dark background color */
  color: #ffffff; /* Light text color */
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

.markdown-container h1,
.markdown-container h2,
.markdown-container h3 {
  color: #ff6347; /* Header color */
}

.markdown-container a {
  color: #1e90ff; /* Link color */
}

.markdown-container a:hover {
  text-decoration: underline;
}

.markdown-container blockquote {
  border-left: 4px solid #333;
  padding-left: 10px;
  color: #999;
}

.markdown-container code {
  background-color: #333;
  padding: 2px 4px;
  border-radius: 4px;
  color: #f8f8f2;
}

.markdown-container pre {
  background-color: #333;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}
